import { InitialValuesType } from 'src/core/types/attendens-apply.type';

export const initialValues: InitialValuesType = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phoneNo: '',
  gender: '',
  nationality: '',
  otherNationality: '',
  companyName: '',
  otherOccupation: '',
  occupation: '',
  qrImageUrl: '',
  reasonToJoin: '',
  other: '',
  interested_attending_event_reason: '',
};
